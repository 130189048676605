<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>Order #{{ order.identifier }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed :to="{ name: 'module-lhl-orders' }"
              >Return to Orders</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <v-row no-gutters>
            <v-col cols="12">
              <v-card outlined class="align-self-stretch">
                <v-card-title class="grey lighten-4">Line Items</v-card-title>
                <v-divider></v-divider>
                <v-simple-table v-if="order.delivery">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(product, i1) in order.delivery.products"
                      :key="i1"
                    >
                      <td class="py-2">
                        <div class="text-subtitle-1 font-weight-bold">
                          {{ product.name }}
                        </div>
                      </td>
                      <td>
                        {{ product.pivot.quantity }} x
                        {{ product.pivot.volume }}kg
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <v-card-text v-else>
                  The Customer has not completed their Order. The Customer will
                  need to complete the payment stage to show their Line Items.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4" offset="8">
              <v-card outlined class="mt-6">
                <v-card-title class="grey lighten-4">Totals</v-card-title>
                <v-divider></v-divider>
                <v-simple-table class="text-right">
                  <tbody v-if="order.order_breeder_detail === null">
                    <tr>
                      <th>Subtotal</th>
                      <td>£{{ order.sub_total }}</td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>£{{ order.delivery_total }}</td>
                    </tr>
                    <tr>
                      <th>Total</th>
                      <td>£{{ order.total }}</td>
                    </tr>
                    <tr>
                      <th>Including VAT of</th>
                      <td>£{{ order.vat_total }}</td>
                    </tr>
                  </tbody>

                  <tbody v-else>
                    <tr>
                      <th>Subtotal</th>
                      <td>£{{ order.sub_total }}</td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>£{{ order.delivery_total }}</td>
                    </tr>
                    <tr>
                      <th>Breeder Discount</th>
                      <td>
                        £{{ order.order_breeder_detail.default_discount }}
                      </td>
                    </tr>
                    <tr>
                      <th>Breeder Credit</th>
                      <td>£{{ order.order_breeder_detail.credits_used }}</td>
                    </tr>

                    <tr>
                      <th>Total</th>
                      <td>£{{ order.total }}</td>
                    </tr>
                    <tr>
                      <th>Including VAT of</th>
                      <td>
                        £{{
                          (order.total - order.total / (1 + 0.2)).toFixed(2)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-select
            label="Order Status"
            v-model="status"
            :items="statuses"
            :loading="statusLoading"
            hint="Update the Order Status here"
            outlined
            persistent-hint
            @change="statusChanged()"
          ></v-select>
          <v-card outlined class="mt-6">
            <v-card-title class="grey lighten-4">Delivery Address</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div>{{ order.address_shipping.full_name }}</div>
              <div>{{ order.address_shipping.line_1 }}</div>
              <div v-if="order.address_shipping.line_2">
                {{ order.address_shipping.line_2 }}
              </div>
              <div v-if="order.address_shipping.line_3">
                {{ order.address_shipping.line_3 }}
              </div>
              <div>{{ order.address_shipping.city }}</div>
              <div v-if="order.address_shipping.county">
                {{ order.address_shipping.county }}
              </div>
              <div>{{ order.address_shipping.postcode }}</div>
              <div v-if="order.address_shipping.country">
                {{ order.address_shipping.country }}
              </div>
              <div class="mt-2">
                <strong>Phone Number:</strong>
                {{ order.address_shipping.phone }}
              </div>
              <div class="mt-2">
                <strong>Email Address:</strong>&nbsp;
                <a :href="`mailto:${order.customer.email}`">{{
                  order.customer.email
                }}</a>
              </div>
            </v-card-text>
          </v-card>
          <v-card outlined class="mt-6">
            <v-card-title class="grey lighten-4">Billing Address</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <div>{{ order.address_billing.full_name }}</div>
              <div>{{ order.address_billing.line_1 }}</div>
              <div v-if="order.address_billing.line_2">
                {{ order.address_billing.line_2 }}
              </div>
              <div v-if="order.address_billing.line_3">
                {{ order.address_billing.line_3 }}
              </div>
              <div>{{ order.address_billing.city }}</div>
              <div v-if="order.address_billing.county">
                {{ order.address_billing.county }}
              </div>
              <div>{{ order.address_billing.postcode }}</div>
              <div v-if="order.address_billing.country">
                {{ order.address_billing.country }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Orders",
          disabled: false,
          exact: true,
          to: {
            name: "module-paw-orders",
          },
        },
        {
          text: "Individual Order",
          disabled: true,
        },
      ],
      statuses: [
        { text: "Processing", value: "processing" },
        { text: "Pending Payment", value: "pending-payment" },
        { text: "Dispatched", value: "dispatched" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Complete", value: "complete" },
      ],
      status: null,
      statusLoading: false,
    };
  },

  computed: {
    order() {
      return this.$store.state.paw["order"];
    },
  },

  mounted() {
    this.status = this.order.status;
  },

  methods: {
    statusChanged: function () {
      this.statusLoading = true;

      this.$store
        .dispatch("paw/saveOrder", {
          appId: this.$route.params.id,
          orderId: this.order.id,
          fields: {
            status: this.status,
          },
        })
        .then(() => {
          this.statusLoading = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Order Status Updated Successfully!",
            color: "success",
          });
        })
        .catch(() => (this.statusLoading = false));
    },
  },
};
</script>
